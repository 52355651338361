<template>
  <v-row class="account">
    <v-col cols="12" class="pa-0">
      <div class="title">
        <div class="text-sm-h5 text-h6 font-weight-bold">
          <router-link to="/accounts" class="white--text">
            <v-icon class="white--text text-sm-h4 text-h5 mb-1">mdi-chevron-left</v-icon> Customer account
          </router-link>
        </div>
      </div>
      <v-card
        v-if="load"
        tile
        elevation="5"
        max-width="1070"
        height="70vh"
        min-height="250"
        class="mx-lg-auto mx-4 mx-sm-15 pa-3 d-flex align-center justify-center flex-column"
      >
        <v-img max-height="80" contain src="@/assets/icon/loader.gif"></v-img>
        <div class="text-body-2 opasity--text">Loading...</div>
      </v-card>
      <div v-else>
        <v-card elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15 pa-sm-10 px-4 py-5">
          <div class="d-flex align-center">
            <div class="text-uppercase text-h5 font-weight-bold">general</div>
            <div class="text-capitalize status ml-10" :class="getColorClass(data.status)">{{ data.status }}</div>
          </div>
          <div class="text-h6 font-weight-regular mt-3"><span class="opasity--text">Balance:</span> {{ data.balance }}</div>
          <div class="px-sm-10 py-sm-5">
            <v-row>
              <v-col cols="12" md="6">
                <div class="font-weight-medium">Account Name</div>
                <div class="opasity--text">{{ data.name }}</div>
                <div class="font-weight-medium mt-3">Main Email</div>
                <div class="opasity--text">{{ data.main_email }}</div>
              </v-col>
              <v-col cols="12" md="6" class="pl-md-16">
                <v-checkbox v-model="data.moderation" color="success" class="mt-0" @change="update">
                  <template v-slot:label>
                    <div class="d-flex flex-column">
                      <div class="font-weight-medium">Moderation</div>
                      <div class="f14 opasity--text">Enable SMS Campaign moderation for the account</div>
                    </div>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
            <v-divider class="my-10"></v-divider>
            <div class="d-flex justify-sm-space-between justify-center">
              <v-btn v-if="data.status != 'blocked'" @click="block" outlined elevation="0" width="184" height="48" color="error">
                Block
              </v-btn>
              <v-btn
                v-if="data.status != 'active'"
                class="ml-sm-auto ml-3"
                elevation="0"
                width="184"
                height="48"
                color="primary"
                @click="active"
              >
                Activate
              </v-btn>
            </div>
          </div>
        </v-card>
        <v-card elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15 mt-5 pb-md-10 pb-4">
          <div class="text-uppercase text-h5 font-weight-bold pa-md-10 pb-md-5 pa-4 pb-2">Members</div>
          <v-divider class="d-sm-block d-none"></v-divider>
          <v-data-table
            hide-default-footer
            fixed-header
            :headers="headers"
            :items="members"
            :items-per-page="-1"
            class="d-sm-block d-none p-table"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="item in items" :key="item.id" height="52" class="link" @click="openItem(item.id)">
                  <td>{{ item.user.first_name }} {{ item.user.last_name }}</td>
                  <td>{{ item.user.email }}</td>
                  <td>
                    <div class="text-capitalize">{{ item.role }}</div>
                  </td>
                  <td class="text-right text-capitalize">{{ item.status }}</td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
          <v-divider class="d-sm-block d-none"></v-divider>
          <div class="d-sm-none">
            <div v-for="item in members" :key="item.id" @click="openItem(item.id)" class="pt-1 px-3">
              <div v-for="head in headers" :key="head.value" class="d-flex my-1">
                <div class="opasity--text font-weight-medium label-table">{{ head.text }}</div>
                <div class="font-weight-medium">{{ item.user[head.value] || item[head.value] }}</div>
              </div>
              <v-divider></v-divider>
            </div>
          </div>
        </v-card>
        <v-card elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15 pa-sm-10 px-4 py-5 mt-5">
          <div class="text-uppercase text-h5 font-weight-bold">Billing</div>
          <div class="px-sm-10 py-sm-5">
            <v-row>
              <v-col cols="12" md="6">
                <v-select
                  v-model="billing.currency"
                  label="Currency"
                  :items="currencyList"
                  item-text="title"
                  item-value="name"
                  return-object
                  filled
                  outlined
                  dense
                  required
                  color="input"
                ></v-select>
                <v-select
                  v-model="billing.payment_mode"
                  label="Payment mode"
                  :items="paymentList"
                  item-text="title"
                  item-value="name"
                  return-object
                  filled
                  outlined
                  dense
                  required
                  color="input"
                ></v-select>
                <v-select
                  v-model="billing.billing_period"
                  label="Billing Period"
                  :items="billengPeriod"
                  item-text="title"
                  item-value="name"
                  return-object
                  filled
                  outlined
                  dense
                  required
                  color="input"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" class="pl-md-16">
                <v-text-field
                  v-model="billing.billing_delay"
                  label="Billing delay days"
                  :error-messages="billingErrors"
                  filled
                  outlined
                  dense
                  required
                  color="input"
                  maxlength="50"
                ></v-text-field>
                <v-text-field
                  v-model="billing.overdraft"
                  label="Overdraft"
                  :error-messages="overdraftErrors"
                  filled
                  outlined
                  dense
                  required
                  color="input"
                  maxlength="50"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider class="my-10"></v-divider>
            <div class="text-right">
              <v-btn elevation="0" width="184" height="48" color="primary" @click="updateBilling">Confirm</v-btn>
            </div>
          </div>
        </v-card>
        <v-card elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15 mt-5">
          <div class="d-flex flex-wrap justify-space-between align-center pt-md-8 pt-4 px-md-10 px-4">
            <div class="text-uppercase text-h5 font-weight-bold">Transactions</div>
            <v-card max-width="550" :width="'100%'" tile flat class="d-flex flex-wrap">
              <v-text-field
                v-model="search"
                placeholder="Search by transaction ID"
                prepend-inner-icon="mdi-magnify"
                hide-details
                :append-icon="search ? 'mdi-close' : ''"
                @click:append="clearSearch"
                outlined
                dense
                required
                color="input"
                @change="(page = 1), geTrans()"
                class="p-table"
              >
              </v-text-field>
              <v-btn @click="newModal = true" class="ml-4" height="48" width="184" outlined color="primary">Record transfer</v-btn>
            </v-card>
          </div>
          <v-divider class="d-sm-block d-none mt-5"></v-divider>
          <v-data-table
            hide-default-footer
            fixed-header
            :headers="headersTrans"
            :items="transactions.result"
            :items-per-page="+itemsPerPage"
            class="p-table"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="(item, i) in items" :key="item.transaction_id + i" height="52" class="link" @click="openDialog(item)">
                  <td>{{ item.transaction_id }}</td>
                  <td>{{ new Date(item.date).toLocaleString() }}</td>
                  <td>{{ item.source_type.title }}</td>
                  <td class="text-right text-capitalize">{{ item.amount }}</td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
          <v-divider></v-divider>
          <v-row class="my-2 pb-2 px-10" align="center" justify="end">
            <span class="grey--text">Rows per page:</span>
            <div class="perPageSelect">
              <v-select
                v-model="itemsPerPage"
                :items="itemsPerPageArray"
                menu-props="auto"
                hide-details
                single-line
                dense
                @change="geTrans"
              ></v-select>
            </div>
            <div>
              <v-btn icon color="secondary" class="mr-1" @click="formerPage">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <span class="mx-4 grey--text">{{ page }} of {{ transactions.pages }}</span>
              <v-btn icon color="secondary" class="ml-1" @click="nextPage">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-row>
        </v-card>
        <v-card elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15 pa-sm-10 px-4 py-5 mt-5">
          <div class="text-uppercase text-h5 font-weight-bold">billing Adress</div>
          <div class="px-sm-10 py-sm-5">
            <v-row>
              <v-col cols="12" md="6">
                <div class="font-weight-medium">Country</div>
                <div class="opasity--text">{{ billingAddress.country || '-' }}</div>
                <div class="font-weight-medium mt-3">City</div>
                <div class="opasity--text">{{ billingAddress.city || '-' }}</div>
                <div class="font-weight-medium mt-3">State</div>
                <div class="opasity--text">{{ billingAddress.state || '-' }}</div>
              </v-col>
              <v-col cols="12" md="6">
                <div class="font-weight-medium">Street</div>
                <div class="opasity--text">{{ billingAddress.address || '-' }}</div>
                <div class="font-weight-medium mt-3">Zip code</div>
                <div class="opasity--text">{{ billingAddress.postal_code || '-' }}</div>
                <div class="font-weight-medium mt-3">Additional Notes</div>
                <div class="opasity--text">{{ billingAddress.notes || '-' }}</div>
              </v-col>
            </v-row>
          </div>
        </v-card>
        <Rest />
        <Smpp />
      </div>
      <v-dialog v-model="newModal" max-width="536">
        <v-card class="pa-md-10 px-sm-5 py-sm-10 py-5 px-4">
          <div class="text-h5 font-weight-bold mb-6">New bank transfer</div>
          <v-row>
            <v-col cols="12" md="8" class="py-0 pr-2">
              <v-menu v-model="modal" :close-on-content-click="false" fixed transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="trans.date"
                    :error-messages="dataErrors"
                    label="Date"
                    append-icon="mdi-calendar"
                    readonly
                    required
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :min="new Date().toISOString().substr(0, 10)"
                  color="primary"
                  v-model="trans.date"
                  @input="modal = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="4" class="py-0 pl-2">
              <v-menu
                ref="menu"
                v-model="modal2"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="trans.time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="trans.time"
                    :error-messages="timeErrors"
                    label="Time"
                    append-icon="mdi-clock-time-four-outline"
                    readonly
                    required
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker v-if="modal2" v-model="trans.time" full-width @click:minute="$refs.menu.save(trans.time)" ampm-in-title>
                </v-time-picker>
              </v-menu>
            </v-col>
            <div class="error--text text-caption ml-3 error-msg" v-if="error == 'date__invalid'">Scheduled date can not be in past</div>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="trans.transId"
                label="Transaction ID"
                :error-messages="transIdErrors"
                filled
                outlined
                dense
                required
                color="input"
                maxlength="50"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0 pr-2">
              <v-text-field
                v-model="trans.amount"
                label="Amount"
                :error-messages="amountErrors"
                filled
                outlined
                dense
                required
                color="input"
                maxlength="50"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0 pl-2">
              <v-text-field v-model="billing.currency.title" label="Currency" filled outlined dense readonly color="input"></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-textarea v-model="trans.notes" label="Notes" filled outlined dense required color="input" rows="5" auto-grow></v-textarea>
            </v-col>
          </v-row>
          <div class="mt-7 d-flex flex-column align-center">
            <v-btn width="184" height="48" color="primary" @click="newTrans">Record</v-btn>
            <v-btn width="184" height="48" text color="secondary" class="mt-3" @click="newModal = false">Close</v-btn>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog v-model="viewModal" max-width="536">
        <v-card class="pa-md-10 px-sm-5 py-sm-10 py-5 px-4">
          <div class="text-h5 font-weight-bold mb-6">Transaction details</div>
          <div class="pa-sm-3">
            <div class="d-flex my-1">
              <div class="font-weight-medium label-table">Transaction ID:</div>
              <div class="opasity--text font-weight-medium">{{ editedItem.transaction_id }}</div>
            </div>
            <div class="d-flex my-1">
              <div class="font-weight-medium label-table">Source:</div>
              <div class="opasity--text font-weight-medium">{{ editedItem.source_type ? editedItem.source_type.title : '-' }}</div>
            </div>
            <div class="d-flex my-1">
              <div class="font-weight-medium label-table">Dite/time:</div>
              <div class="opasity--text font-weight-medium">{{ new Date(editedItem.date).toLocaleString() }}</div>
            </div>
            <div class="d-flex my-1">
              <div class="font-weight-medium label-table">Amount:</div>
              <div class="opasity--text font-weight-medium">{{ editedItem.amount }}</div>
            </div>
            <div class="d-flex my-1">
              <div class="font-weight-medium label-table">Notes:</div>
              <div class="opasity--text font-weight-medium">{{ editedItem.notes }}</div>
            </div>
          </div>
          <v-divider class="my-5"></v-divider>
          <div class="text-center">
            <v-btn outlined width="184" height="48" text color="secondary" @click="closeDialog">Close</v-btn>
          </div>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import Rest from '@/components/account/Rest';
import Smpp from '@/components/account/Smpp';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  components: {
    Rest,
    Smpp,
  },
  validations: {
    billing: {
      billing_delay: { required },
      overdraft: { required },
    },
    trans: {
      amount: { required },
      transId: { required },
      date: { required },
      time: { required },
    },
  },
  data() {
    return {
      load: true,
      headers: [
        { text: 'Name', value: 'first_name', sortable: false },
        { text: 'Email', value: 'email', sortable: false },
        { text: 'Role', value: 'role', sortable: false },
        { text: 'Status', align: 'end', value: 'status', sortable: false },
      ],
      search: '',
      itemsPerPage: 10,
      page: 1,
      headersTrans: [
        { text: 'Transaction ID', value: 'id', sortable: false },
        { text: 'Date', value: 'date', sortable: false },
        { text: 'Source', value: 'source', sortable: false },
        { text: 'Amount', align: 'end', value: 'amount', sortable: false },
      ],
      newModal: false,
      viewModal: false,
      trans: {
        time: new Date().toTimeString().substr(0, 5),
        date: new Date().toISOString().substr(0, 10),
      },
      editedItem: {},
      modal: false,
      modal2: false,
      error: null,
      statusError: null,
    };
  },
  mounted() {
    this.getData();
    this.geTrans();
    this.$store.dispatch('getAccountMembers');
    this.$store.dispatch('getAccountBilling');
    this.$store.dispatch('getCurrency');
    this.$store.dispatch('getBillengPeriod');
    this.$store.dispatch('getPeymentModes');
    this.$store.dispatch('getAccountAddress');
    this.$store.dispatch('getPagination');
    this.$store.dispatch('getAccountRest');
    this.$store.dispatch('getAccountSmpp');
  },
  methods: {
    getColorClass(block) {
      if (block == 'created') {
        return 'yellow darken-2';
      } else if (block == 'active') {
        return 'success';
      } else if (block == 'blocked') {
        return 'error';
      }
    },
    notifi(btn) {
      if (btn == 'update') {
        this.$notify({ message: 'Account moderation has been updated', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'upBilling') {
        this.$notify({ message: 'Account billing has been updated', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'newTrans') {
        this.$notify({ message: 'Account transactions has been created', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'search400') {
        this.$notify({
          message: 'This field must be at least 3 characters, maximum 10',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == '500') {
        this.$notify({
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    async getData() {
      await this.$store.dispatch('getAccount').finally(() => {
        this.load = false;
      });
    },
    async active() {
      await this.$store.dispatch('activeAccount');
    },
    async block() {
      await this.$store.dispatch('blockAccount');
    },
    async update() {
      const data = new Object();
      data.moderation = this.data.moderation;
      await this.$store
        .dispatch('updateAccount', data)
        .then(() => {
          this.notifi('update');
        })
        .catch((e) => {
          this.error = e.response.data.error[0];
          this.notifi();
        });
    },
    openItem(id) {
      this.$router.push(`/accounts/${this.$route.params.accountId}/members/${id}`);
    },
    async updateBilling() {
      this.$v.billing.$touch();
      if (!this.$v.billing.$invalid) {
        this.error = null;
        const data = new Object();
        data.currency = this.billing.currency.name;
        data.payment_mode = this.billing.payment_mode ? this.billing.payment_mode.name : null;
        data.billing_period = this.billing.billing_period.name;
        data.billing_delay = +this.billing.billing_delay;
        data.overdraft = +this.billing.overdraft;
        await this.$store
          .dispatch('updateAccountBilling', data)
          .then(() => {
            this.notifi('upBilling');
          })
          .catch((e) => {
            this.error = e.response.data.error[0];
          });
      }
    },
    clearSearch() {
      this.page = 1;
      this.search = '';
      this.geTrans();
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
      this.geTrans();
    },
    nextPage() {
      if (this.page != this.data.pages) {
        this.page += 1;
        this.geTrans();
      }
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.geTrans();
      }
    },
    async geTrans() {
      await this.$store
        .dispatch('getAccountTransactions', {
          pageSize: this.itemsPerPage,
          search: this.search,
          page: this.page,
        })
        .catch((e) => {
          this.getDataError(e);
        });
    },
    getDataError(e) {
      this.statusError = e.response.status;
      if (e.response.data.error[0] === 'page__out_of_bounds') {
        this.page = 1;
        this.geTrans();
      } else if (this.statusError >= 400 && this.statusError < 500) {
        this.notifi('search400');
      } else if (this.statusError >= 500) {
        this.notifi('500');
      }
    },
    openDialog(item) {
      this.editedItem = Object.assign({}, item);
      this.viewModal = true;
    },
    closeDialog() {
      this.viewModal = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, {});
      });
    },
    async newTrans() {
      this.$v.trans.$touch();
      if (!this.$v.trans.$invalid) {
        this.error = null;
        const fullDate = new Date(this.trans.date).getTime() + this.mutTime(this.trans.time) + new Date().getTimezoneOffset() * 60000;
        const data = new Object();
        data.amount = +this.trans.amount;
        data.transaction_id = this.trans.transId;
        data.notes = this.trans.notes;
        data.date = fullDate;
        data.source_type = 'bank_transfer';
        await this.$store
          .dispatch('updateAccountTransactions', data)
          .then(() => {
            this.notifi('newTrans');
            this.geTrans();
            this.newModal = false;
          })
          .catch((e) => {
            this.error = e.response.data.error[0];
          });
      }
    },
    mutTime(hhmm) {
      hhmm = `${hhmm.toString()}:00`;

      var parts = hhmm.split(':');
      var n = parts.length;
      var ms = 0;
      var i;

      for (i = 0; i < parts.length; i++) {
        var part = parseInt(parts[n - 1 - i]);
        if (i === 0) {
          ms += part * 1000;
        } else if (i === 1) {
          ms += part * 6e4;
        } else if (i === 2) {
          ms += part * 36e5;
        }
      }
      return ms;
    },
  },
  watch: {
    newModal() {
      this.$v.trans.$reset();
      this.trans = {
        time: new Date().toTimeString().substr(0, 5),
        date: new Date().toISOString().substr(0, 10),
      };
    },
    viewModal(val) {
      val || this.closeDialog();
    },
  },
  computed: {
    data() {
      return this.$store.getters.account;
    },
    members() {
      return this.$store.getters.members;
    },
    billing() {
      return this.$store.getters.billing;
    },
    currencyList() {
      return this.$store.getters.currency;
    },
    billengPeriod() {
      return this.$store.getters.billengPeriod;
    },
    paymentList() {
      return this.$store.getters.paymentModes;
    },
    billingAddress() {
      return this.$store.getters.accountAddress;
    },
    itemsPerPageArray() {
      return this.$store.getters.pagination;
    },
    transactions() {
      return this.$store.getters.transactionsList;
    },
    billingErrors() {
      const errors = [];
      if (!this.$v.billing.billing_delay.$dirty) {
        return errors;
      }
      !this.$v.billing.billing_delay.required && errors.push('Please enter account billing delay');
      this.error == 'billing_delay__invalid' && errors.push('Provided billing delay is not valid');
      return errors;
    },
    overdraftErrors() {
      const errors = [];
      if (!this.$v.billing.overdraft.$dirty) {
        return errors;
      }
      !this.$v.billing.overdraft.required && errors.push('Please enter account overdraft');
      this.error == 'overdraft__invalid' && errors.push('Provided overdraft is not valid');
      return errors;
    },
    amountErrors() {
      const errors = [];
      if (!this.$v.trans.amount.$dirty) {
        return errors;
      }
      !this.$v.trans.amount.required && errors.push('Please enter amount value');
      this.error == 'amount__invalid' && errors.push('Provided amount is not valid');
      return errors;
    },
    transIdErrors() {
      const errors = [];
      if (!this.$v.trans.transId.$dirty) {
        return errors;
      }
      !this.$v.trans.transId.required && errors.push('Please enter transaction id');
      this.error == 'transaction_id__invalid' && errors.push('Provided transaction id is not valid');
      return errors;
    },
    dataErrors() {
      const errors = [];
      if (!this.$v.trans.date.$dirty) {
        return errors;
      }
      !this.$v.trans.date.required && errors.push('Date is required');
      this.error == 'date__invalid' && errors.push('');
      return errors;
    },
    timeErrors() {
      const errors = [];
      if (!this.$v.trans.time.$dirty) {
        return errors;
      }
      !this.$v.trans.time.required && errors.push('Tiem is required');
      this.error == 'date__invalid' && errors.push('');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setAccount', {});
    this.$store.dispatch('setAccountMembers', []);
    this.$store.dispatch('setAccountBilling', { billing_period: {}, currency: {}, payment_mode: {} });
    this.$store.dispatch('setAccountAddress', {});
    this.$store.dispatch('setAccountTransactions', {});
    this.$store.dispatch('setAccountRest', { sandbox: {}, live: {} });
    this.$store.dispatch('setAccountSmpp', { sandbox: {}, live: {} });
  },
};
</script>

<style lang="scss">
.account {
  .status {
    line-height: 24px;
    padding: 0 20px;
    width: max-content;
    border-radius: 24px;
    font-size: 14px;
    color: white;
  }
}
</style>
