<template>
  <v-card elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15 pa-sm-10 px-4 py-5 mt-5">
    <div class="text-uppercase text-h5 font-weight-bold">SMPP API</div>
    <div class="px-sm-10 py-sm-5">
      <v-row>
        <v-col cols="12" md="6">
          <v-checkbox v-model="accountsSmpp.sandbox.allowed" color="success" class="mt-0">
            <template v-slot:label>
              <div class="font-weight-medium">Sandbox access allowed</div>
            </template>
          </v-checkbox>
          <v-text-field v-model="accountsSmpp.sandbox.system_id" label="System ID" outlined dense disabled color="input"></v-text-field>
          <v-text-field
            v-model="accountsSmpp.sandbox.password"
            label="Password"
            append-icon="mdi-lock"
            outlined
            dense
            disabled
            color="input"
          ></v-text-field>
          <v-text-field
            v-model="accountsSmpp.sandbox.max_binds"
            label="Max binds"
            :error-messages="sandMaxBindErrors"
            filled
            outlined
            dense
            required
            color="input"
          ></v-text-field>
          <v-text-field
            v-model="accountsSmpp.sandbox.inbound_window"
            label="Inbownd window"
            :error-messages="sandInWinErrors"
            filled
            outlined
            dense
            required
            color="input"
          ></v-text-field>
          <v-text-field
            v-model="accountsSmpp.sandbox.inbound_rate_limit"
            label="Inbownd rate, m/s"
            :error-messages="sandInRateErrors"
            filled
            outlined
            dense
            required
            color="input"
          ></v-text-field>
          <v-text-field
            v-model="accountsSmpp.sandbox.outbound_window"
            label="Outbownd window"
            :error-messages="sandOutWinErrors"
            filled
            outlined
            dense
            required
            color="input"
          ></v-text-field>
          <v-text-field
            v-model="accountsSmpp.sandbox.outbound_rate_limit"
            label="Outbownd rate, m/s"
            :error-messages="sandOutRateErrors"
            filled
            outlined
            dense
            required
            color="input"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="pl-md-16">
          <v-checkbox v-model="accountsSmpp.live.allowed" color="success" class="mt-0">
            <template v-slot:label>
              <div class="font-weight-medium">Live access allowed</div>
            </template>
          </v-checkbox>
          <v-text-field v-model="accountsSmpp.sandbox.system_id" label="System ID" outlined dense disabled color="input"></v-text-field>
          <v-text-field
            v-model="accountsSmpp.live.password"
            label="Password"
            append-icon="mdi-lock"
            outlined
            dense
            disabled
            color="input"
          ></v-text-field>
          <v-text-field
            v-model="accountsSmpp.live.max_binds"
            label="Max binds"
            :error-messages="liveMaxBindErrors"
            filled
            outlined
            dense
            required
            color="input"
          ></v-text-field>
          <v-text-field
            v-model="accountsSmpp.live.inbound_window"
            label="Inbownd window"
            :error-messages="liveInWinErrors"
            filled
            outlined
            dense
            required
            color="input"
          ></v-text-field>
          <v-text-field
            v-model="accountsSmpp.live.inbound_rate_limit"
            label="Inbownd rate, m/s"
            :error-messages="liveInRateErrors"
            filled
            outlined
            dense
            required
            color="input"
          ></v-text-field>
          <v-text-field
            v-model="accountsSmpp.live.outbound_window"
            label="Outbownd window"
            :error-messages="liveOutWinErrors"
            filled
            outlined
            dense
            required
            color="input"
          ></v-text-field>
          <v-text-field
            v-model="accountsSmpp.live.outbound_rate_limit"
            label="Outbownd rate, m/s"
            :error-messages="liveOutRateErrors"
            filled
            outlined
            dense
            required
            color="input"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-divider class="my-10"></v-divider>
      <div class="text-right">
        <v-btn @click="update(false)" elevation="0" width="184" height="48" color="secondary">Save</v-btn>
        <v-btn @click="update(true)" class="ml-5" elevation="0" width="184" height="48" color="primary">Save and Apply</v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import { required, numeric } from 'vuelidate/lib/validators';

export default {
  validations: {
    accountsSmpp: {
      live: {
        inbound_window: { required, numeric },
        outbound_window: { required, numeric },
        max_binds: { required, numeric },
        inbound_rate_limit: { required, numeric },
        outbound_rate_limit: { required, numeric },
      },
      sandbox: {
        inbound_window: { required, numeric },
        outbound_window: { required, numeric },
        max_binds: { required, numeric },
        inbound_rate_limit: { required, numeric },
        outbound_rate_limit: { required, numeric },
      },
    },
  },
  data() {
    return {
      error: [],
    };
  },
  methods: {
    notifi(btn) {
      if (btn == 'update') {
        this.$notify({ message: 'Account SMPP API has been updated', horizontalAlign: 'center', verticalAlign: 'top' });
      }
    },
    async update(action) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.error = [];
        const data = new Object();
        data.sandbox_allowed = this.accountsSmpp.sandbox.allowed;
        data.max_binds_sandbox = +this.accountsSmpp.sandbox.max_binds;
        data.inbound_window_sandbox = +this.accountsSmpp.sandbox.inbound_window;
        data.inbound_rate_limit_sandbox = +this.accountsSmpp.sandbox.inbound_rate_limit;
        data.outbound_window_sandbox = +this.accountsSmpp.sandbox.outbound_window;
        data.outbound_rate_limit_sandbox = +this.accountsSmpp.sandbox.outbound_rate_limit;
        data.live_allowed = this.accountsSmpp.live.allowed;
        data.max_binds_live = +this.accountsSmpp.live.max_binds;
        data.inbound_window_live = +this.accountsSmpp.live.inbound_window;
        data.inbound_rate_limit_live = +this.accountsSmpp.live.inbound_rate_limit;
        data.outbound_window_live = +this.accountsSmpp.live.outbound_window;
        data.outbound_rate_limit_live = +this.accountsSmpp.live.outbound_rate_limit;
        data.apply_now = action;
        await this.$store
          .dispatch('updateAccountSmpp', data)
          .then(() => {
            this.notifi('update');
          })
          .catch((e) => {
            this.error = e.response.data.error;
          });
      }
    },
  },
  computed: {
    accountsSmpp() {
      return this.$store.getters.accountsSmpp;
    },
    liveInWinErrors() {
      const errors = [];
      if (!this.$v.accountsSmpp.live.inbound_window.$dirty) {
        return errors;
      }
      !this.$v.accountsSmpp.live.inbound_window.required && errors.push('Please enter account inbound window live');
      !this.$v.accountsSmpp.live.inbound_window.numeric && errors.push('Please enter account inbound window live');
      this.error.find((item) => item == 'inbound_window_live__invalid') && errors.push('Provided inbound window live is not valid');
      return errors;
    },
    sandInWinErrors() {
      const errors = [];
      if (!this.$v.accountsSmpp.sandbox.inbound_window.$dirty) {
        return errors;
      }
      !this.$v.accountsSmpp.sandbox.inbound_window.required && errors.push('Please enter account inbound window sandbox');
      !this.$v.accountsSmpp.sandbox.inbound_window.numeric && errors.push('Please enter account inbound window sandbox');
      this.error.find((item) => item == 'inbound_window_sandbox__invalid') && errors.push('Provided inbound window sandbox is not valid');
      return errors;
    },
    liveOutWinErrors() {
      const errors = [];
      if (!this.$v.accountsSmpp.live.outbound_window.$dirty) {
        return errors;
      }
      !this.$v.accountsSmpp.live.outbound_window.required && errors.push('Please enter account outbound window live');
      !this.$v.accountsSmpp.live.outbound_window.numeric && errors.push('Please enter account outbound window live');
      this.error.find((item) => item == 'outbound_window_live__invalid') && errors.push('Provided outbound window live is not valid');
      return errors;
    },
    sandOutWinErrors() {
      const errors = [];
      if (!this.$v.accountsSmpp.sandbox.outbound_window.$dirty) {
        return errors;
      }
      !this.$v.accountsSmpp.sandbox.outbound_window.required && errors.push('Please enter account outbound window sandbox');
      !this.$v.accountsSmpp.sandbox.outbound_window.numeric && errors.push('Please enter account outbound window sandbox');
      this.error.find((item) => item == 'outbound_window_sandbox__invalid') && errors.push('Provided outbound window sandbox is not valid');
      return errors;
    },
    liveMaxBindErrors() {
      const errors = [];
      if (!this.$v.accountsSmpp.live.max_binds.$dirty) {
        return errors;
      }
      !this.$v.accountsSmpp.live.max_binds.required && errors.push('Please enter account max binds live');
      !this.$v.accountsSmpp.live.max_binds.numeric && errors.push('Please enter account max binds live');
      this.error.find((item) => item == 'max_binds_live__invalid') && errors.push('Provided max binds live is not valid');
      return errors;
    },
    sandMaxBindErrors() {
      const errors = [];
      if (!this.$v.accountsSmpp.sandbox.max_binds.$dirty) {
        return errors;
      }
      !this.$v.accountsSmpp.sandbox.max_binds.required && errors.push('Please enter account max binds sandbox');
      !this.$v.accountsSmpp.sandbox.max_binds.numeric && errors.push('Please enter account max binds sandbox');
      this.error.find((item) => item == 'max_binds_sandbox__invalid') && errors.push('Provided max binds sandbox is not valid');
      return errors;
    },
    liveInRateErrors() {
      const errors = [];
      if (!this.$v.accountsSmpp.live.inbound_rate_limit.$dirty) {
        return errors;
      }
      !this.$v.accountsSmpp.live.inbound_rate_limit.required && errors.push('Please enter account inbound rate limit live');
      !this.$v.accountsSmpp.live.inbound_rate_limit.numeric && errors.push('Please enter account inbound rate limit live');
      this.error.find((item) => item == 'inbound_rate_limit_live__invalid') && errors.push('Provided inbound rate limit live is not valid');
      return errors;
    },
    sandInRateErrors() {
      const errors = [];
      if (!this.$v.accountsSmpp.sandbox.inbound_rate_limit.$dirty) {
        return errors;
      }
      !this.$v.accountsSmpp.sandbox.inbound_rate_limit.required && errors.push('Please enter account inbound rate limit sandbox');
      !this.$v.accountsSmpp.sandbox.inbound_rate_limit.numeric && errors.push('Please enter account inbound rate limit sandbox');
      this.error.find((item) => item == 'inbound_rate_limit_sandbox__invalid') &&
        errors.push('Provided inbound rate limit sandbox is not valid');
      return errors;
    },
    liveOutRateErrors() {
      const errors = [];
      if (!this.$v.accountsSmpp.live.outbound_rate_limit.$dirty) {
        return errors;
      }
      !this.$v.accountsSmpp.live.outbound_rate_limit.required && errors.push('Please enter account outbound rate limit live');
      !this.$v.accountsSmpp.live.outbound_rate_limit.numeric && errors.push('Please enter account outbound rate limit live');
      this.error.find((item) => item == 'outbound_rate_limit_live__invalid') &&
        errors.push('Provided outbound rate limit live is not valid');
      return errors;
    },
    sandOutRateErrors() {
      const errors = [];
      if (!this.$v.accountsSmpp.sandbox.outbound_rate_limit.$dirty) {
        return errors;
      }
      !this.$v.accountsSmpp.sandbox.outbound_rate_limit.required && errors.push('Please enter account outbound rate limit sandbox');
      !this.$v.accountsSmpp.sandbox.outbound_rate_limit.numeric && errors.push('Please enter account outbound rate limit sandbox');
      this.error.find((item) => item == 'outbound_rate_limit_sandbox__invalid') &&
        errors.push('Provided outbound rate limit sandbox is not valid');
      return errors;
    },
  },
};
</script>
