<template>
  <v-card elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15 pa-sm-10 px-4 py-5 mt-5">
    <div class="text-uppercase text-h5 font-weight-bold">REST API</div>
    <div class="px-sm-10 py-sm-5">
      <v-row>
        <v-col cols="12" md="6">
          <v-checkbox v-model="accountsRest.sandbox.allowed" color="success" class="mt-0">
            <template v-slot:label>
              <div class="font-weight-medium">Sandbox access allowed</div>
            </template>
          </v-checkbox>
          <v-text-field
            v-model="accountsRest.sandbox.sid"
            label="Account SID (Sandbox)"
            outlined
            dense
            disabled
            color="input"
          ></v-text-field>
          <v-text-field
            v-model="accountsRest.sandbox.secret"
            label="Secret Key (Sandbox)"
            append-icon="mdi-lock"
            outlined
            dense
            disabled
            color="input"
          ></v-text-field>
          <v-textarea
            v-model="accountsRest.sandbox.webhook"
            label="Webhook URL (Sandbox)"
            outlined
            dense
            disabled
            color="input"
            rows="5"
            auto-grow
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="6" class="pl-md-16">
          <v-checkbox v-model="accountsRest.live.allowed" color="success" class="mt-0">
            <template v-slot:label>
              <div class="font-weight-medium">Live access allowed</div>
            </template>
          </v-checkbox>
          <v-text-field v-model="accountsRest.live.sid" label="Account SID (Live)" outlined dense disabled color="input"></v-text-field>
          <v-text-field
            v-model="accountsRest.live.secret"
            label="Secret Key (Live)"
            append-icon="mdi-lock"
            outlined
            dense
            disabled
            color="input"
          ></v-text-field>
          <v-textarea
            v-model="accountsRest.live.webhook"
            label="Webhook URL (Live)"
            outlined
            dense
            disabled
            color="input"
            rows="5"
            auto-grow
          ></v-textarea>
        </v-col>
      </v-row>
      <v-divider class="my-10"></v-divider>
      <div class="text-right">
        <v-btn @click="update(false)" elevation="0" width="184" height="48" color="secondary">Save</v-btn>
        <v-btn @click="update(true)" class="ml-5" elevation="0" width="184" height="48" color="primary">Save and Apply</v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  methods: {
    notifi(btn) {
      if (btn == 'update') {
        this.$notify({ message: 'Account REST API has been updated', horizontalAlign: 'center', verticalAlign: 'top' });
      }
    },
    async update(action) {
      const data = new Object();
      data.sandbox_allowed = this.accountsRest.sandbox.allowed;
      data.live_allowed = this.accountsRest.live.allowed;
      data.apply_now = action;
      await this.$store.dispatch('updateAccountRest', data).then(() => {
        this.notifi('update');
      });
    },
  },
  computed: {
    accountsRest() {
      return this.$store.getters.accountsRest;
    },
  },
};
</script>
